import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react"

import * as styles from "./Navigation.module.scss"

import { Globe, Coffee, ChevronDown } from 'lucide-react';
import { Discord, Twitter } from '@icons-pack/react-simple-icons';
import LanguageSwitcher from "../components/LanguageSwitcher";

const Navigation = ({transparency}) => {
  let [lswVisible, setLswVisible] = React.useState(false)
  let [dropDownVisible, setDropDownVisible] = React.useState(false)
  let [atTop, setAtTop] = React.useState(false);

  const {t} = useTranslation();

  const updateTransparency = () => {
    if (typeof window === "undefined") return;

    // eslint-disable-next-line no-undef
    if (window.scrollY < 15) {
      if (!atTop) setAtTop(true);
    } else {
      if (atTop) setAtTop(false);
    }
  };

  const clickOutsite = (e) => {
    if(!e.target.hasAttribute("data-noddclose") && !e.target.parentElement.hasAttribute("data-noddclose")) {
      setDropDownVisible(false)
    }
  }

  React.useEffect(() => {
    if (typeof window === "undefined") return;

    // eslint-disable-next-line no-undef
    window.addEventListener("scroll", updateTransparency);
    // eslint-disable-next-line no-undef
    window.addEventListener("navigate", updateTransparency);

    updateTransparency();

    // eslint-disable-next-line no-undef
    let int = window.setInterval(updateTransparency, 10000);
    // eslint-disable-next-line no-undef
    window.addEventListener("click", clickOutsite);

    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener("scroll", updateTransparency);
      // eslint-disable-next-line no-undef
      window.removeEventListener("navigate", updateTransparency);
      // eslint-disable-next-line no-undef
      window.removeEventListener("click", clickOutsite);

      // eslint-disable-next-line no-undef
      window.clearInterval(int);
    };
  });


  return <div className={styles.topbar + (atTop && transparency ? " "+styles.transparent : "")}>
      <nav>
          <Link to="/" className={styles.logo}><span>B</span>eam<span>B</span>ot <span className={styles.beta}>BETA</span></Link>
          {/*<Link to="/test">Test</Link>*/}
          
          
          <a href={"https://twitter.com/BeamB0t"} target="_blank" rel="noreferrer" title="Check out our Twitter" aria-label="Visit our Twitter"><Twitter /></a>
          <a onClick={(e) => {setLswVisible(true); e.preventDefault()}} href="#" role="button" aria-label="Switch Language"><Globe/></a>
          <a onClick={(e) => {setDropDownVisible(!dropDownVisible); e.preventDefault()}} href="#" aria-label={""} data-noddclose="true"><ChevronDown style={{transition: "transform .25s", transform: "rotate("+(dropDownVisible ? "180deg" : "0deg") +")"}}/></a>
          <div className={styles.dropdown + " " + (dropDownVisible ? styles.active : "")} data-noddclose="true">
            <div data-noddclose="true">
              <Link to={"/team/"} title={t("team")} data-noddclose="true" activeClassName={styles.active}><Coffee /> {t("team")}</Link>
              <Link to={"/discord/"} title="Join our Discord" data-noddclose="true" activeClassName={styles.active}><Discord /> {t("discord")}</Link>
            </div>
          </div>
      </nav>
      <LanguageSwitcher visible={lswVisible} callback={() => {setLswVisible(false)}} />
  </div>
}

export default Navigation