import { Helmet } from "gatsby-plugin-react-i18next"
import * as React from "react"

const Seo = (props) => {
    return <Helmet defaultTitle="BeamBot" title={props.title} titleTemplate={"%s | BeamBot.top"}>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;800&display=swap" rel="stylesheet" /> 
    </Helmet>;
}

export default Seo