import * as React from "react"

import * as styles from "./index.module.scss"

import PropTypes from "prop-types"

import "./global.scss"
import Navigation from "./Navigation";
import Seo from "./SEO";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

const DefaultLayout = (props) => {
    let children = props.children;

    let {t} = useTranslation();

    return (<div className={styles.layout}>
        <Seo title={props.title} />
        <Navigation transparency={props.topbarTransparent} />
        <main>
            {children}
        </main>
        <footer>&copy; {(new Date()).getFullYear()}, BeamBot | <Link to="/legal/imprint">{t("imprint")}</Link> | <Link to="/legal/privacy">{t("privacyPolicy")}</Link></footer>
    </div>)
}

DefaultLayout.props = {
    "title": PropTypes.string.isRequired,
    topbarTransparent: PropTypes.bool
}

export default DefaultLayout