import { Link, useI18next } from "gatsby-plugin-react-i18next";
import * as React from "react"
import * as ReactDOM from "react-dom"

import * as styles from "./LanguageSwitcher.module.scss"

const LanguageSwitcher = (props) => {
    const { originalPath } = useI18next();


    if(typeof document !== "undefined") return ReactDOM.createPortal(
        <div className={styles.languageSwitcher + " " + (props.visible ? styles.active : "")} onClick={props.callback} role="modal">
            <div className={styles.languageSwitcherInner}>
                <span className={styles.lswHeader}>Languages</span>
                <Link to={originalPath} language={"en"}>English</Link>
                <Link to={originalPath} language={"de"}>German</Link>
            </div>
        </div>,
        document.body
    )

    return <span></span>
}

export default LanguageSwitcher;